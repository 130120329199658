export enum ErrorCode {
  DefaultRuntimeError = 1,
  ReactiveProxyError,
  DocCollectionError,
  ModelCRUDError,
  ValueNotExists,
  ValueNotInstanceOf,
  ValueNotEqual,
  MigrationError,
  SchemaValidateError,
  TransformerError,
  InlineEditorError,
  TransformerNotImplementedError,
  EdgelessExportError,
  CommandError,
  EventDispatcherError,
  SelectionError,
  GfxBlockElementError,
  MissingViewModelError,
  DatabaseBlockError,
  ParsingError,
  UserAbortError,
  ExecutionError,

  // Fatal error should be greater than 10000
  DefaultFatalError = 10000,
  NoRootModelError,
  NoSurfaceModelError,
  NoneSupportedSSRError,
}
